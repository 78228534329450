@import 'base.scss';

.wrapper {
	display: flex;
	justify-content: space-between;
}

.label {
	color: inherit;
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 12px;
	padding: 10px 0 3px;

	&--is-secondary {
		color: $grey-45;
	}
}

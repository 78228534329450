@import 'base.scss';

.url {
	&__input {
		padding-left: 30px;
	}

	&__holder {
		position: relative;

		&:after {
			font-size: 18px;
			left: 7px;
			top: 50%;
			transform: translateY(-50%);
		}

		&--https {
			&:after {
				@extend .icon-lock-locked;
				color: $green-35;
			}
		}

		&--http {
			&:after {
				@extend .icon-lock-unlocked;
				color: $grey-60;
			}
		}
	}

	&__tooltip {
		white-space: pre-line;
	}
}
